import { Lock } from 'lucide-react';
import { Fragment, MouseEventHandler } from 'react';
import {
  DropdownMenu as DropdownMenuBase,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';
import { getListItemKey } from 'src/utils/format';

export type ItemType = {
  name: string;
  key?: string;
  onSelect: MouseEventHandler;
  isLocked?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
};

export type GroupType = {
  items: ItemType[];
};

export default function DropdownMenu({
  items,
  groups,
  trigger,
  menuHeader,
  disabled = false,
  renderItem
}: {
  items?: ItemType[];
  groups?: GroupType[];
  trigger: React.ReactNode;
  menuHeader?: string;
  disabled?: boolean;
  renderItem?: (item: ItemType) => React.ReactNode;
}) {
  const renderDropdownItem = (item: ItemType, idx: number) => {
    return (
      <DropdownMenuItem
        key={getListItemKey(idx)}
        onClick={(e) => {
          item.onSelect(e);
          e.stopPropagation();
        }}
        disabled={item.disabled}
      >
        {renderItem ? (
          renderItem(item)
        ) : (
          <div className="flex grow flex-nowrap items-center">
            {item.icon && <div className="mr-sm">{item.icon}</div>}
            <p>{item.name}</p>
            {item.isLocked && <Lock className="ml-sm scale-50" />}
          </div>
        )}
      </DropdownMenuItem>
    );
  };
  return disabled ? (
    <div className="inline-block">{trigger}</div>
  ) : (
    <DropdownMenuBase>
      <DropdownMenuTrigger>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent className="z-[600]">
        {menuHeader && (
          <>
            <p className="p-sm font-bold">{menuHeader}</p>
            <DropdownMenuSeparator />
          </>
        )}
        {groups?.length > 0
          ? groups?.map((group, index) => {
              return (
                <Fragment key={getListItemKey(index)}>
                  {index !== 0 && <DropdownMenuSeparator />}
                  <DropdownMenuGroup>
                    {group?.items.map((item, index) => {
                      return renderDropdownItem(item, index);
                    })}
                  </DropdownMenuGroup>
                </Fragment>
              );
            })
          : items?.length > 0 &&
            items.map((item, index) => {
              return renderDropdownItem(item, index);
            })}
      </DropdownMenuContent>
    </DropdownMenuBase>
  );
}
