import { SubscriptionPlanType } from 'src/components/core/organisms/dialogs/SubscribeDialog';
import { SUBSCRIPTION_PLANS } from 'src/config';
import useSubscriptionStore from 'src/hooks/store/useSubcriptionStore';

export type PaywallFeatureType =
  | 'ai-reductions'
  | 'share-view-image-upload'
  | 'share-view-ai-text-generation'
  | 'share-view-team-members'
  | 'integrations'
  | 'team'
  | 'groups-and-permissions'
  | 'custom-emission-factors';

const paywalls: {
  [key in PaywallFeatureType]: {
    subtitleText: string;
    requiredPlan: SubscriptionPlanType;
  };
} = {
  'ai-reductions': {
    subtitleText: 'Generate reduction suggestions with Pro+.',
    requiredPlan: SUBSCRIPTION_PLANS.PRO_PLUS
  },
  'share-view-image-upload': {
    subtitleText:
      'Upload custom images to your public sustainability page with Pro+.',
    requiredPlan: SUBSCRIPTION_PLANS.PRO_PLUS
  },
  'share-view-ai-text-generation': {
    subtitleText:
      'Use EmitIQ AI to generate text for your public sustainability page with Pro+.',
    requiredPlan: SUBSCRIPTION_PLANS.PRO_PLUS
  },
  'share-view-team-members': {
    subtitleText:
      'Showcase your team on your public sustainability page with Pro+.',
    requiredPlan: SUBSCRIPTION_PLANS.PRO_PLUS
  },
  integrations: {
    subtitleText:
      'Integrate your bank accounts and accounting software with Pro+.',
    requiredPlan: SUBSCRIPTION_PLANS.PRO_PLUS
  },
  team: {
    subtitleText:
      'Invite teammates to your organization with Advanced.',
    requiredPlan: SUBSCRIPTION_PLANS.ADVANCED
  },
  'groups-and-permissions': {
    subtitleText:
      'Assign roles to individuals in your organization with Advanced.',
    requiredPlan: SUBSCRIPTION_PLANS.ADVANCED
  },
  'custom-emission-factors': {
    subtitleText:
      'Create and use custom emission factors with Advanced.',
    requiredPlan: SUBSCRIPTION_PLANS.ADVANCED
  }
};

export default function usePaywall(feature: PaywallFeatureType) {
  const { activeSubscriptionTier } = useSubscriptionStore(
    (store) => ({
      activeSubscriptionTier: store.activeSubscriptionTier
    })
  );

  if (!feature) {
    return null;
  }

  // Assumes that plans in SUBSCRIPTION_PLANS are ordered from lowest to highest tier
  const currentPlanIdx = Object.keys(SUBSCRIPTION_PLANS).findIndex(
    (key) => {
      return SUBSCRIPTION_PLANS[key].tier === activeSubscriptionTier;
    }
  );
  const requiredPlanIdx = Object.keys(SUBSCRIPTION_PLANS).findIndex(
    (key) => {
      return (
        SUBSCRIPTION_PLANS[key].tier ===
        paywalls[feature].requiredPlan.tier
      );
    }
  );

  // If current plan is greater than or equal to the required plan, the user is authorized
  let isAuthorized = false;
  if (
    currentPlanIdx >= requiredPlanIdx &&
    currentPlanIdx !== -1 &&
    requiredPlanIdx !== -1
  ) {
    isAuthorized = true;
  }

  return {
    isAuthorized,
    requiredPlan: paywalls[feature].requiredPlan,
    subtitleText: paywalls[feature].subtitleText
  };
}
